import React, { createContext, useState, useCallback, ReactNode, useEffect } from 'react';

interface Track {
  name: string;
  artists: { name: string }[];
  album: {
    images: { url: string }[];
  };
}

interface PlaybackContextProps {
  currentTrack: Track | null;
  isPlaying: boolean;
  setCurrentTrack: (track: Track | null) => void;
  setIsPlaying: (playing: boolean) => void;
}

export const PlaybackContext = createContext<PlaybackContextProps>({
  currentTrack: null,
  isPlaying: false,
  setCurrentTrack: () => {},
  setIsPlaying: () => {},
});

export const PlaybackProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentTrack, setCurrentTrackState] = useState<Track | null>(() => {
    const saved = localStorage.getItem('current_track');
    return saved ? JSON.parse(saved) : null;
  });
  
  const [isPlaying, setIsPlayingState] = useState<boolean>(() => {
    return localStorage.getItem('is_playing') === 'true';
  });

  useEffect(() => {
    if (currentTrack) {
      localStorage.setItem('current_track', JSON.stringify(currentTrack));
    }
    localStorage.setItem('is_playing', isPlaying.toString());
  }, [currentTrack, isPlaying]);

  const setCurrentTrack = useCallback((track: Track | null) => {
    setCurrentTrackState(track);
  }, []);

  const setIsPlaying = useCallback((playing: boolean) => {
    setIsPlayingState(playing);
  }, []);

  return (
    <PlaybackContext.Provider value={{ currentTrack, isPlaying, setCurrentTrack, setIsPlaying }}>
      {children}
    </PlaybackContext.Provider>
  );
};
