import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./VideoChat.css";
import { useWebRTC } from "./useWebRTC";
import { getProfilePictureSrc } from '../../utils/getProfilePictureSrc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash, faMicrophone, faMicrophoneSlash, faComments, faUserPlus, faTimes, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { addDoc, collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { firestore } from '../../../src/config/firebase';
import { useTranslation } from 'react-i18next';

interface VideoChatProps {
  callId: string | null;
  userId: string;
  displayName: string;
  profilePicture: string;
  onFindOrCreateCall: () => void;
  onLeaveCall: () => void;
  isSearching: boolean;
  onFriendRequestStatus: (success: boolean, message: string) => void;
}

interface FriendshipStatus {
  is_friend: boolean;
  has_sent_request: boolean;
}

export interface ChatMessage {
  id: string;
  senderId: string;
  senderName: string;
  message: string;
  timestamp: number;
}

const VideoChat: React.FC<VideoChatProps> = ({
  callId,
  userId,
  displayName,
  profilePicture,
  onFindOrCreateCall,
  onLeaveCall,
  isSearching,
  onFriendRequestStatus
}) => {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [remoteDisplayName, setRemoteDisplayName] = useState<string | null>(null);
  const [remoteUserId, setRemoteUserId] = useState<string | null>(null);
  const [remoteUserProfilePicture, setRemoteUserProfilePicture] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showFriendRequestButton, setShowFriendRequestButton] = useState(false);

  // Friendship status state
  const [friendshipStatus, setFriendshipStatus] = useState<FriendshipStatus>({
    is_friend: false,
    has_sent_request: false,
  });

  // Get current user from useAuth hook
  const { user } = useAuth();

  const localVideoRef = useRef<HTMLVideoElement>(null);
  const remoteVideoRef = useRef<HTMLVideoElement>(null);
  const friendCardRef = useRef<HTMLDivElement>(null);

  const { setupWebRTC, cleanupWebRTC } = useWebRTC(userId, displayName, profilePicture);

  // Track if audio is available
  const [hasAudio, setHasAudio] = useState(true);

  // Chat states
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const { t } = useTranslation();

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  // Use useLayoutEffect to ensure scrolling happens after DOM updates
  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Modify the media devices setup
  useEffect(() => {
    let stream: MediaStream | null = null;

    const setupMediaDevices = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ 
          video: true, 
          audio: true 
        });
        
        // Check if we got audio track
        const hasAudioTrack = stream.getAudioTracks().length > 0;
        setHasAudio(hasAudioTrack);
        
        // Set initial mute state based on track enabled status
        if (hasAudioTrack) {
          setIsMuted(!stream.getAudioTracks()[0].enabled);
        }

        setLocalStream(stream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error accessing media devices:", error);
        // Handle specific errors
        if (error instanceof DOMException) {
          if (error.name === 'NotAllowedError') {
            setHasAudio(false);
            console.error('Microphone permission denied');
          } else if (error.name === 'NotFoundError') {
            setHasAudio(false);
            console.error('No microphone found');
          }
        }
      }
    };

    setupMediaDevices();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const handleFindOrCreateCall = useCallback(() => {
    setRemoteStream(null);
    onFindOrCreateCall();
  }, [onFindOrCreateCall]);

  useEffect(() => {
    if (!localStream || !isSearching || !callId) return;

    const cleanup = setupWebRTC(
      callId,
      localStream,
      setRemoteStream,
      handleFindOrCreateCall,
      ({ name, id, profilePicture }: { name: string; id: string; profilePicture: string }) => {
        setRemoteDisplayName(name);
        setRemoteUserId(id);
        setRemoteUserProfilePicture(profilePicture);
      }
    );

    return () => cleanup();
  }, [localStream, setupWebRTC, handleFindOrCreateCall, isSearching, callId]);

  useEffect(() => {
    if (remoteStream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const handleLeaveCall = useCallback(() => {
    cleanupWebRTC();
    setRemoteStream(null);
    setRemoteDisplayName(null);
    setRemoteUserId(null);
    setRemoteUserProfilePicture(null);
    setShowChat(false);
    setMessages([]);
    setNewMessage('');
    onLeaveCall();
  }, [cleanupWebRTC, onLeaveCall]);

  // Enhanced mute toggle function
  const handleMuteToggle = useCallback(() => {
    if (localStream) {
      const audioTracks = localStream.getAudioTracks();
      if (audioTracks.length > 0) {
        const audioTrack = audioTracks[0];
        audioTrack.enabled = !audioTrack.enabled;
        setIsMuted(!audioTrack.enabled);
        
        // Optional: Provide user feedback
        const status = audioTrack.enabled ? 'unmuted' : 'muted';
        console.log(`Microphone ${status}`);
      }
    }
  }, [localStream]);

  const handleFriendIconClick = () => {
    if (friendshipStatus.has_sent_request) {
      onFriendRequestStatus(false, t('videoChat.friendship.alreadySent'));
      return;
    }
    if (friendshipStatus.is_friend) {
      onFriendRequestStatus(false, t('videoChat.friendship.alreadyFriends'));
      return;
    }
    setShowFriendRequestButton(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      friendCardRef.current &&
      !friendCardRef.current.contains(event.target as Node)
    ) {
      setShowFriendRequestButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sendFriendRequest = async () => {
    if (!remoteUserId) {
      onFriendRequestStatus(false, t('videoChat.friendship.noRemoteUser'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/send_friend_request`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          from_user_id: userId,
          to_user_id: remoteUserId,
        }),
      });

      const data = await response.json();
      console.log('Friend Request Response:', data);

      if (data.success) {
        setFriendshipStatus((prev) => ({
          ...prev,
          has_sent_request: true,
        }));
        setShowFriendRequestButton(false);
        onFriendRequestStatus(true, t('videoChat.friendship.sendSuccess'));
      } else {
        onFriendRequestStatus(false, data.message || t('videoChat.friendship.sendError'));
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
      onFriendRequestStatus(false, t('videoChat.friendship.sendError'));
    }
  };

  useEffect(() => {
    const fetchFriendshipStatus = async () => {
      if (!remoteUserId || !user) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/friendship_status`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            current_user_id: user.id,
            remote_user_id: remoteUserId,
          }),
        });

        const data = await response.json();
        console.log('Friendship Status Response:', data); // Debug log

        if (data.success) {
          setFriendshipStatus({
            is_friend: data.is_friend,
            has_sent_request: data.has_sent_request,
          });
        } else {
          console.error('Failed to fetch friendship status:', data.message);
        }
      } catch (error) {
        console.error('Error fetching friendship status:', error);
      }
    };

    fetchFriendshipStatus();
  }, [remoteUserId, user]);

  // Message sending function
  const sendMessage = async () => {
    if (!newMessage.trim() || !callId) return;

    const message: ChatMessage = {
      id: Date.now().toString(),
      senderId: userId,
      senderName: displayName,
      message: newMessage.trim(),
      timestamp: Date.now(),
    };

    try {
      await addDoc(collection(firestore, `calls/${callId}/messages`), message);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Message listener to track unread messages
  useEffect(() => {
    if (!callId) return;

    const unsubscribe = onSnapshot(
      query(
        collection(firestore, `calls/${callId}/messages`),
        orderBy('timestamp', 'asc')
      ),
      (snapshot) => {
        const newMessages = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as ChatMessage[];
        setMessages(newMessages);
        
        // If chat is not open, increment unread count for new messages from other user
        if (!showChat) {
          const lastMessageTimestamp = messages[messages.length - 1]?.timestamp || 0;
          const newUnreadMessages = newMessages.filter(
            msg => msg.senderId !== userId && 
            msg.timestamp > lastMessageTimestamp
          );
          setUnreadCount(prev => prev + newUnreadMessages.length);
        }
      }
    );

    return () => unsubscribe();
  }, [callId, userId, showChat, messages]);

  // Reset unread count when opening chat
  const handleChatToggle = () => {
    setShowChat(!showChat);
    if (!showChat) {
      setUnreadCount(0);
    }
  };

  // Handle chat window visibility
  useEffect(() => {
    // Close chat window if there's no call or remote stream
    if (!callId || !remoteStream) {
      setShowChat(false);
      // Optional: Clear messages when call ends
      setMessages([]);
      setNewMessage('');
    }
  }, [callId, remoteStream]);

  return (
    <div className="video-chat">
      <div className="beta-banner">
        <div className="beta-banner-content">
          <span className="beta-tag">BETA</span>
          <span className="beta-message">
            {t('videoChat.beta.message', 'Video chat is currently down. We are working on improvements and it will be available soon!')}
          </span>
        </div>
      </div>
      <div className="video-container">
        {/* Local Video */}
        <div className="local-video-wrapper">
          <video
            ref={localVideoRef}
            autoPlay
            muted
            playsInline
            className="local-video"
            style={{ transform: "scaleX(-1)" }}
          />
          <div className="local-user-info friend-card-connect">
            <div className="friend-pic-connect">
              {getProfilePictureSrc(profilePicture).startsWith('http') ? (
                <img src={getProfilePictureSrc(profilePicture)} alt={`${displayName}'s profile`} />
              ) : (
                <div className="friend-placeholder-connect">{displayName.charAt(0)}</div>
              )}
            </div>
            <div className="friend-info-connect">
              <h3>{displayName}</h3>
            </div>
          </div>
        </div>

        {/* Remote Video */}
        <div className="remote-video-wrapper">
          {callId && remoteStream ? (
            <>
              <video ref={remoteVideoRef} autoPlay playsInline className="remote-video" />
              {remoteDisplayName && remoteUserId && (
                <div className="remote-user-info friend-card-connect" ref={friendCardRef}>
                  <div className="friend-pic-connect">
                    {getProfilePictureSrc(remoteUserProfilePicture || '').startsWith('http') ? (
                      <img src={getProfilePictureSrc(remoteUserProfilePicture || '')} alt={`${remoteDisplayName}'s profile`} />
                    ) : (
                      <div className="friend-placeholder-connect">{remoteDisplayName.charAt(0)}</div>
                    )}
                  </div>
                  <div className="friend-info-connect">
                    <h3>{remoteDisplayName}</h3>
                  </div>
                  <div className="friend-icon" onClick={handleFriendIconClick}>
                    <FontAwesomeIcon icon={faUserPlus} />
                  </div>

                  {/* Friend Request Button */}
                  {showFriendRequestButton && !friendshipStatus.is_friend && !friendshipStatus.has_sent_request && (
                    <div className="friend-request-button-wrapper">
                      <button onClick={sendFriendRequest} className="send-friend-request-button">
                        <FontAwesomeIcon icon={faUserPlus} /> {t('videoChat.buttons.sendRequest')}
                      </button>
                    </div>
                  )}

                  {/* Friend Request Sent Feedback */}
                  {friendshipStatus.is_friend && (
                    <p className="is-friend">{t('videoChat.friendship.areFriends')}</p>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="waiting-message">
              <p>
                {isSearching 
                  ? t('videoChat.status.looking')
                  : t('videoChat.status.waiting')
                }
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Floating Action Bar */}
      <div className="action-bar">
        {/* Enhanced Mute/Unmute Button */}
        <button 
          onClick={handleMuteToggle} 
          className={`action-button-video ${!hasAudio ? 'disabled' : ''} ${isMuted ? 'muted' : ''}`}
          disabled={!hasAudio}
          title={!hasAudio 
            ? t('videoChat.status.noMic')
            : t(isMuted ? 'videoChat.status.unmute' : 'videoChat.status.mute')
          }
        >
          <FontAwesomeIcon 
            icon={isMuted ? faMicrophoneSlash : faMicrophone} 
            className={isMuted ? 'muted-icon' : ''}
          />
        </button>

        {/* Chat Button */}
        <div className="chat-button-wrapper">
          <button onClick={handleChatToggle} className="action-button-video">
            <FontAwesomeIcon icon={faComments} />
          </button>
          {unreadCount > 0 && (
            <div className="unread-badge">
              {unreadCount}
            </div>
          )}
        </div>

        {/* Leave Call Button */}
        {callId && (
          <button onClick={handleLeaveCall} className="leave-call-button">
            <FontAwesomeIcon icon={faPhoneSlash} />
          </button>
        )}

        {/* Start Call Button */}
        {!callId && !isSearching && (
          <button onClick={onFindOrCreateCall} className="start-call-button">
            <FontAwesomeIcon icon={faPhone} />
          </button>
        )}
      </div>

      {showChat && (
        <div className="video-chat-message-window">
          <div className="video-chat-message-header">
            <h3>{t('videoChat.chat.title')}</h3>
            <button className="video-chat-close-button" onClick={() => setShowChat(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="video-chat-messages-container">
            {messages.map((msg) => (
              <div key={msg.id} className={`video-chat-message-bubble ${msg.senderId === userId ? 'sent' : 'received'}`}>
                <div className="video-chat-message-sender">
                  {msg.senderId === userId ? t('videoChat.chat.you') : msg.senderName}
                </div>
                <div className="video-chat-message-content">{msg.message}</div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="video-chat-input-container">
            <input
              type="text"
              className="video-chat-message-input"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
              placeholder={t('videoChat.chat.placeholder')}
            />
            <button className="video-chat-send-button" onClick={sendMessage}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoChat;
