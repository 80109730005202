import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './SpeakeasyProModal.css';
//import { FaSpotify, FaYoutube } from 'react-icons/fa';

const SpeakeasyProModal = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsAnimating(false);
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleLearnMore = () => {
    onRequestClose();
    navigate('/pro');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t("proModal.title.highlight")}
      className={`modal speakeasy-pro-modal ${isAnimating ? 'open' : ''}`}
      overlayClassName="overlay"
    >
      <button className="close-modal-button" onClick={onRequestClose}>{t("proModal.close")}</button>
      <div className="pro-content">
        <h2>
          {t("proModal.title.prefix")} <span className="highlight">{t("proModal.title.highlight")}</span>
        </h2>
        <img 
          src="/images/home-page-video-chat.png" 
          alt={t("proModal.imageAlt")} 
          className="pro-image"
        />
        <div className="pro-pricing">
          <span className="pro-price">{t("proModal.pricing.amount")}</span>
          <span className="pro-period">{t("proModal.pricing.period")}</span>
        </div>
        <ul className="pro-features">
         
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("proModal.features.videoChat.title")}
              <span className="feature-tooltip">{t("proModal.features.videoChat.tooltip")}</span>
            </span>
          </li>
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("proModal.features.ai.title")}
              <span className="feature-tooltip">{t("proModal.features.ai.tooltip")}</span>
            </span>
          </li>
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("proModal.features.progress.title")}
              <span className="feature-tooltip">{t("proModal.features.progress.tooltip")}</span>
            </span>
          </li>
          <li>
            <span className="checkmark">✓</span>
            <span className="feature-text">{t("proModal.features.wordpacks.title")}
              <span className="feature-tooltip">{t("proModal.features.wordpacks.tooltip")}</span>
            </span>
          </li>
        </ul>
        <button 
          className="upgrade-button"
          onClick={handleLearnMore}
        >
          {t("proModal.learnMore")} →
        </button>
      </div>
    </Modal>
  );
};

export default SpeakeasyProModal;
