import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './MembersModal.css';

const MembersModal = ({ isOpen, onClose, members, fullMemberDetails }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!isOpen) return null;

  const getProfilePictureSrc = (profilePicture) => {
    if (profilePicture?.startsWith('http')) {
      return profilePicture;
    }
    return `${process.env.REACT_APP_API_BASE_URL}/${profilePicture}`;
  };

  const getUserStatus = (lastLoginDate) => {
    if (!lastLoginDate) return 'offline';
    
    const today = new Date().toDateString();
    const lastLogin = new Date(lastLoginDate).toDateString();
    
    return today === lastLogin ? 'online' : 'away';
  };

  const getStatusText = (status) => {
    switch(status) {
      case 'online':
        return t('membersModal.stats.status.online');
      case 'away':
        return t('membersModal.stats.status.away');
      default:
        return t('membersModal.stats.status.offline');
    }
  };

  const handleProfileClick = (id) => {
    navigate(`/public_profile/${id}`);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{t('membersModal.title')}</h2>
          <button className="close-button" onClick={onClose}>
            {t('membersModal.close')}
          </button>
        </div>
        <div className="members-list">
          {members.map(member => {
            const fullDetails = fullMemberDetails[member.user_id] || {};
            const status = getUserStatus(fullDetails.last_login_date);
            
            return (
              <div className="member-row" key={member.user_id}>
                <div className="member-info">
                  <img
                    src={getProfilePictureSrc(member.profile_picture)}
                    alt={member.display_name}
                    className="member-profile-pic"
                    onClick={() => handleProfileClick(member.user_id)}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className="member-name">
                    {member.display_name}
                    {fullDetails.is_verified && (
                      <img 
                        src="/images/verified-badge.webp" 
                        alt={t('profile.verified')} 
                        className="verified-badge" 
                        title={t('profile.verifiedAccount')}
                      />
                    )}
                    {fullDetails.is_pro && (
                      <span className="pro-badge" title={t('profile.proUser')}>PRO</span>
                    )}
                  </span>
                </div>
                <div className="member-stats">
                  <span className="message-count">
                    {t('membersModal.stats.messages', { count: member.messageCount })}
                  </span>
                  <span 
                    className={`status-indicator ${status}`}
                    title={getStatusText(status)}
                  ></span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MembersModal;
